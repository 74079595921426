import { getBaseUrl } from "./getBaseUrl";

export const generateMarker = (data, id, type, action) => {
	const baseUrl = getBaseUrl(true, "/img/", false, false, true);

	const partnerImage = `${baseUrl}assets/maps_red.png?w=64`;
	const firmImage = `${baseUrl}assets/maps_blue.png?w=64`;
	const mixedImage = `${baseUrl}assets/maps_black.png?w=64`;

	let icon = mixedImage;
	// let icon;

	// console.log("data in generateMarker", data);

	if (data.partners.length && data.firms.length) {
		icon = mixedImage;
	} else if (data.partners.length) {
		icon = partnerImage;
	} else if (data.firms.length) {
		icon = firmImage;
	}

	return {
		position: {
			lat: data.location.lat,
			lng: data.location.lng,
		},
		icon: {
			url: icon,
			scaledSize: { width: 32, height: 32 },
		},
		data: {
			...data,
			type: type,
			id: id,
			action,
		},
	};
};
