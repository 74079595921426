import React, { Suspense, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../components/Advantages";
import Brands from "../components/Brands";
import FAQ from "../components/FAQ";
import MapPopup from "../components/MapPopup";
import Rechner from "../components/Rechner";
import Steps from "../components/Steps";
import TextContent from "../components/TextContent";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import useIntersectionObserver from "../helpers/useInteractionObserver";
// import Vergleich from "../components/Vergleich";

const MultiMap = React.lazy(() => import("../components/MultiMap"));
const Video = React.lazy(() => import("../components/Video"));
const Reviews = React.lazy(() => import("../components/Reviews"));

const Home = ({
	brands,
	randomBrands,
	faq,
	partners,
	filteredPartners,
	firms,
	advantages,
	steps,
	markersFromServer,
	filterPartnersByBrand = (partnersToFilter) => {
		console.log(partnersToFilter);
	},
}) => {
	const mapsSection = useRef(null);
	const isMapsSectionVisible = useIntersectionObserver(mapsSection);

	const videoSection = useRef(null);
	const isVideoSectionVisible = useIntersectionObserver(videoSection);

	const reviewSection = useRef(null);
	const isReviewSectionVisible = useIntersectionObserver(reviewSection);

	const [openMap, setOpenMap] = useState(false);

	let maps = null;

	if (window.innerWidth > 800) {
		maps = (
			<div>
				{isMapsSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<MultiMap
							partners={partners}
							brands={brands}
							firms={firms}
							markersFromServer={markersFromServer}
						/>
					</Suspense>
				)}
			</div>
		);
	} else {
		maps = (
			<div>
				<MapPopup
					partners={partners}
					brands={brands}
					firms={firms}
					open={openMap}
					markersFromServer={markersFromServer}
					closePopup={() => setOpenMap(false)}
				/>
				<div className="mobileMap">
					<button
						className={"mobileMapButton"}
						onClick={() => setOpenMap(true)}
						type={"button"}
					>
						Karte mit Radl-Händlern & Arbeitgebern öffnen
					</button>
				</div>
			</div>
		);
	}

	return (
		<div>
			<Helmet>
				<title>
					FIRMENRADL Bike-Leasing | Abgefahren! Coole Bikes für wenig Kohle!
				</title>
				<meta
					name="description"
					content="Jetzt Top-Bike leasen und bis zu 35 % günstiger finanzieren als bei Kauf! Inklusive Reparaturen und Versicherung. Details und Rechenbeispiel auf firmenradl.at"
				/>
				<meta name="theme-color" content="#E0334C" />
			</Helmet>
			{/*<div className="vergleichsContainer">*/}
			{/*<Vergleich />*/}
			<Rechner />
			{/*</div>*/}
			<TextContent
				headline="AUF DIE ZUKUNFT ABFAHREN"
				text="<ul>
    <li><em>Wir</em> sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität.</li>
    <li><em>Du</em> bekommst dein gewünschtes Top-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über
        deinen Arbeitgeber mittels Gehaltsabzug.
    </li>
    <li><em>Du</em> sparst dir bis zu 35 % zum normalen Kauf, profitierst von inkludierten Versicherungen & Reparaturen und bist
        somit sicher abgefahren günstig unterwegs.
    </li>
</ul>"
				image="assets/filler_mood_lime_1.jpg"
				buttonVisible
				buttonText={"Keine Zeit verlieren und gleich registrieren"}
				buttonLink={getRegisterUrl()}
				imageVisibleByDefault
			/>
			<Steps steps={steps} />
			<section className="suspenseSection videoSection" ref={videoSection}>
				{isVideoSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<Video />
					</Suspense>
				)}
			</section>
			<Advantages advantages={advantages} />
			<Brands
				brands={brands}
				randomBrands={randomBrands}
				partners={filteredPartners}
				markersFromServer={markersFromServer}
				filterPartnersByBrand={(id) => filterPartnersByBrand(id)}
			/>
			<section
				className="suspenseSection multiMapSection"
				ref={mapsSection}
				id={"map"}
			>
				{maps}
			</section>
			<FAQ faq={faq} type={"users"} />
			<section className="suspenseSection reviewSection" ref={reviewSection}>
				{isReviewSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<Reviews />
					</Suspense>
				)}
			</section>
		</div>
	);
};

export default Home;
