import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import MapMarkerPopup from "./MapMarkerPopup";

let userInputTimeout = false;

export const generateListItem = (data, type, action) => {
	return {
		data: {
			type: type,
			label: data.title,
			street: data.street,
			zipCity: `${data.zip} ${data.city}`,
			image: data.logo,
			id: data.id,
			marker: data.marker,
			bikes: data.bikes,
			instagram: data.instagram,
			linkedin: data.linkedin,
			facebook: data.facebook,
			homepage: data.homepage,
			action,
		},
	};
};

const MapFirmenradl = ({
	initialMarkers = [],
	openedWindow = false,
	setOpenedWindow,
	center,
	setCenter,
	zoom,
	setZoom,
}) => {
	const [activeInfoWindow, setActiveInfoWindow] = useState("");
	const [markers, setMarkers] = useState(initialMarkers);

	const [mapref, setMapRef] = React.useState(null);

	useEffect(() => {
		if (markers.length !== initialMarkers.length) {
			setMarkers(initialMarkers);
		}

		if (openedWindow !== false) {
			setActiveInfoWindow(openedWindow);
		} else {
			setActiveInfoWindow("");
		}

		if (mapref) {
			mapref.setCenter(center);
			mapref.setZoom(zoom);
		}
	}, [initialMarkers, markers, openedWindow, center, mapref, zoom]);

	const handleOnLoad = (map) => {
		setMapRef(map);
	};

	const containerStyle = {
		width: "100%",
		height: "100%",
	};

	const mapClicked = (event) => {
		console.log("clicked Map");
		console.log(event.latLng.lat(), event.latLng.lng());
	};

	const onMapMoved = () => {
		if (userInputTimeout) {
			clearTimeout(userInputTimeout);
		}

		if (mapref) {
			userInputTimeout = setTimeout(() => {
				const newCenter = mapref.getCenter();
				if (center.lat !== newCenter.lat()) {
					setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
				}

				if (zoom !== mapref.getZoom()) {
					setZoom(mapref.getZoom());
				}
				userInputTimeout = false;
			}, 500);
		}
	};

	const markerClicked = (marker) => {
		console.log("marker clicked");
		setOpenedWindow(marker.data.id);
		setCenter(marker.position);
		setZoom(14);
	};

	const markerDragEnd = (marker, index) => {
		console.log("marker Dragged");
		console.log(marker, index);
	};

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={zoom}
			onClick={mapClicked}
			onCenterChanged={onMapMoved}
			onZoomChanged={onMapMoved}
			onLoad={handleOnLoad}
		>
			{markers.map((marker, index) => (
				<Marker
					key={marker.data.id}
					position={marker.position}
					label={marker.label}
					draggable={marker.draggable}
					icon={marker.icon}
					onDragEnd={() => markerDragEnd(marker, index)}
					onClick={() => markerClicked(marker, index)}
				>
					{activeInfoWindow === marker.data.id && (
						<InfoWindow
							position={marker.position}
							onCloseClick={() => {
								console.log("closing", marker);
							}}
						>
							<MapMarkerPopup marker={marker} />
						</InfoWindow>
					)}
				</Marker>
			))}
		</GoogleMap>
	);
};

export default MapFirmenradl;
