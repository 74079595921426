import { CookieBanner } from "@kubide.dev/react-cookie-law-full";
import { LoadScript } from "@react-google-maps/api";
import axios from "axios";
import { filter, random } from "lodash";
import React, { useEffect, useState, lazy, Suspense } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";

import { getBaseUrl } from "./helpers/getBaseUrl";

import Error404 from "./pages/404";
import Admin from "./pages/Admin";
import DownloadPage from "./pages/Download";
import Haendlermap from "./pages/Haendlermap";
import Home from "./pages/Home";
const Faq = lazy(() => import("./pages/FAQ"));
const Firms = lazy(() => import("./pages/Firms"));
const Partners = lazy(() => import("./pages/Partners"));
const Ablauf = lazy(() => import("./pages/Ablauf"));
const Gewinnspiel = lazy(() => import("./pages/Gewinnspiel"));
const Marken = lazy(() => import("./pages/Brands"));
const Imprint = lazy(() => import("./pages/Imprint"));
const HilfeHaendler = lazy(() => import("./pages/HilfeHaendler"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Cookies = lazy(() => import("./pages/Cookies"));
const PureRechner = lazy(() => import("./pages/PureRechner"));
const KontaktPage = lazy(() => import("./pages/KontaktPage"));

const tagManagerArgs = {
	gtmId: "GTM-5PSXW8Q",
};

const googleMapsLibraries = ["places"];

export const googleMapsApiKey = "AIzaSyBzOBnmJmz3YqWzNSfJ8WKijLDUf0mEpQk";

// @todo: https://github.com/JustFly1984/react-google-maps-api/issues/70
// see: https://github.com/JustFly1984/react-google-maps-api/issues/159#issuecomment-502446663
class LoadScriptOnlyIfNeeded extends LoadScript {
	componentDidMount() {
		const cleaningUp = true;
		const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
		const isAlreadyLoaded =
			window.google?.maps && document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
		if (!isAlreadyLoaded && isBrowser) {
			// @ts-ignore
			if (window.google && !cleaningUp) {
				console.error("google api is already presented");
				return;
			}

			this.isCleaningUp().then(this.injectScript);
		}

		if (isAlreadyLoaded) {
			this.setState({ loaded: true });
		}
	}
}

const App = () => {
	const [loading, setLoading] = useState(true);
	const [brands, setBrands] = useState(false);
	const [firms, setFirms] = useState(false);
	const [partners, setPartners] = useState(false);
	const [markersFromServer, setMarkersFromServer] = useState(false);

	const [filterPartnersByBrand, setFilterPartnersByBrand] = useState(false);
	const [randomBrands, setRandomBrands] = useState([]);

	const [faq, setFaq] = useState(false);
	const [faqOld, setFaqOld] = useState(false);
	const [cms, setCms] = useState(false);
	const [files, setFiles] = useState(false);

	const [advantagesUser, setAdvantagesUser] = useState(false);
	const [advantagesFirm, setAdvantagesFirm] = useState(false);
	const [advantagesPartner, setAdvantagesPartner] = useState(false);

	const [stepsUser, setStepsUser] = useState(false);
	const [stepsFirm, setStepsFirm] = useState(false);

	const location = useLocation();

	const [marketing, setMarketing] = useState(false);
	const [marketingActivated, setMarketingActivated] = useState(false);

	const fullPage = !window.location.pathname.includes("/embed");
	// console.log("full page", fullPage);

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		// Update the document title using the browser API
		if (loading === true) {
			const url = getBaseUrl(true, "/data", false, true);

			axios
				.get(url)
				.then((response) => {
					// handle success
					setBrands(response.data.brands);
					setFirms(response.data.firms);
					setPartners(response.data.partners);
					setMarkersFromServer(response.data.markers);

					// console.log("multiple markers", response.data.multipleMarkers);

					setFaq(response.data.faq);
					setFaqOld(response.data.faqOld);
					setCms(response.data.cms);
					setFiles(response.data.files);

					setAdvantagesUser(response.data.advantagesUser);
					setAdvantagesFirm(response.data.advantagesFirm);
					setAdvantagesPartner(response.data.advantagesPartner);

					setStepsUser(response.data.stepsUser);
					setStepsFirm(response.data.stepsFirm);

					generateRandomBrands(response.data.brands);

					// console.info("fetched all data!");
				})
				.catch((error) => {
					// handle error
					console.log("my error message");
					console.error(error);
					console.log("end of my error message");
				});

			setLoading(false);
		}
	}, [loading]);

	const activatePreferences = () => {
		// console.info("ACTIVATE PREFERENCES");
	};

	const activateStatistics = () => {
		// console.info("ACTIVATE STATISTIC");
	};

	if (marketing) {
		if (!marketingActivated) {
			TagManager.initialize(tagManagerArgs);
			setMarketingActivated(true);
			// console.info("Enabled Marketing Cookies");
		}
	}

	const generateRandomBrands = (allBrands) => {
		const randomBrandsToAdd = [];
		const randomBrandsToAddIds = [];
		const selectedMarkenIds = [];

		Object.keys(allBrands).forEach((key) => {
			randomBrandsToAddIds.push(key);
		});

		// limited set
		for (let j = 0; j < 12; j++) {
			let newIndex = null;
			let newId = null;

			do {
				// check for duplicate brands, get a new brand in case duplicates are found
				newIndex = random(randomBrandsToAddIds.length - 1);
				newId = randomBrandsToAddIds[newIndex];
			} while (selectedMarkenIds.indexOf(newId) >= 0);

			selectedMarkenIds.push(newId);
			randomBrandsToAdd.push(allBrands[newId]);
		}

		setRandomBrands(randomBrandsToAdd);
	};

	let filteredPartners = [];
	if (filterPartnersByBrand !== false) {
		filteredPartners = filter(partners, (o) => {
			let found = false;
			o.bikes.forEach((bike) => {
				if (bike.id === filterPartnersByBrand) {
					found = true;
				}
			});
			return found;
		});
	}

	const className = location.pathname
		.replace("/", " ")
		.replace("/", " ")
		.replace(".", "_");

	return (
		<div className={`App ${className}`}>
			<ScrollToTop />

			<Helmet>
				<title>
					Das FIRMENRADL ist dein Radl - Marken & Modelle frei wählbar
				</title>
				<meta name="theme-color" content="#E0334C" />
			</Helmet>

			{fullPage && (
				<CookieBanner
					message="Wir setzen Cookies ein, um verschiedene Funktionen der auf dieser Website angebotenen Services zu gewährleisten, Zugriffe darauf zu analysieren und das Ergebnis zu deren Optimierung zu nutzen. Cookies werden von uns auch dazu verwendet, zielgerichtete Werbung zu ermöglichen. Daten werden zu diesem Zweck auch an verbundene Unternehmen und unsere Partner weitergegeben. Durch die Nutzung unserer Webseite stimmen Sie der Verwendung von Cookies zu. Mehr Informationen finden Sie in unserer Datenschutzerklärung und Cookie Policy"
					wholeDomain={true}
					onAccept={() => {}}
					onAcceptPreferences={() => {
						activatePreferences();
					}}
					onAcceptStatistics={() => {
						activateStatistics();
					}}
					onAcceptMarketing={() => {
						if (marketing === false) {
							setTimeout(() => {
								setMarketing(true);
							}, 200);
							// console.info("Accepted Marketing Cookies");
						}
					}}
					privacyPolicyLinkText={"Datenschutzerklärung"}
					policyLink={"/cms/datenschutzerklarung"}
					acceptButtonText={"Cookies akzeptieren"}
					declineButtonText={"Cookies ablehnen"}
					showDeclineButton={true}
					managePreferencesButtonText={"Privatsphäreeinstellungen bearbeiten"}
					savePreferencesButtonText={"Privatsphäreeinstellungen speichern"}
					showStatisticsOption={false}
					necessaryOptionText={"Notwendige"}
					statisticsOptionText={"Statistiken"}
					marketingOptionText={"Marketing"}
					preferencesOptionText={"Einstellungen"}
					onDeclinePreferences={() => {}}
					onDeclineStatistics={() => {}}
					onDeclineMarketing={() => {}}
				/>
			)}

			{fullPage && <Header />}

			<LoadScriptOnlyIfNeeded
				googleMapsApiKey={googleMapsApiKey}
				libraries={googleMapsLibraries}
			>
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={1000}>
						<Suspense fallback={<div className="suspenseFallback" />}>
							<Routes location={location}>
								<Route
									path="/"
									element={
										<Home
											brands={brands}
											randomBrands={randomBrands}
											filterPartnersByBrand={(id) =>
												setFilterPartnersByBrand(id)
											}
											filteredPartners={filteredPartners}
											faq={faq}
											partners={partners}
											firms={firms}
											advantages={advantagesUser}
											steps={stepsUser}
											markersFromServer={markersFromServer}
										/>
									}
								/>
								<Route
									path="/arbeitgeber"
									element={
										<Firms
											firms={firms}
											faq={faq}
											advantages={advantagesFirm}
											steps={stepsFirm}
											markersFromServer={markersFromServer}
										/>
									}
								/>

								<Route
									path="/haendler"
									element={
										<Partners
											partners={partners}
											faq={faq}
											advantages={advantagesPartner}
											markersFromServer={markersFromServer}
										/>
									}
								/>

								<Route path="/gewinnspiel" element={<Gewinnspiel />} />

								<Route
									path="/ablauf"
									element={<Ablauf partners={partners} />}
								/>
								<Route path="/faq" element={<Faq faq={faq} />} />
								<Route path="/faq2022" element={<Faq faq={faqOld} />} />
								<Route
									path="/download"
									element={<DownloadPage files={files} />}
								/>
								<Route
									path="/haendlermap"
									element={
										<Haendlermap
											partners={partners}
											markersFromServer={markersFromServer}
										/>
									}
								/>
								<Route
									path="/hilfebereich-fur-handler"
									element={<HilfeHaendler />}
								/>
								<Route path="/impressum" element={<Imprint />} />
								<Route
									path="/datenschutzerklarung"
									element={<Privacy content={cms.privacy} />}
								/>
								<Route path="/cookie-policy" element={<Cookies />} />
								<Route
									path="/marken"
									element={
										<Marken
											brands={brands}
											filterPartnersByBrand={(id) =>
												setFilterPartnersByBrand(id)
											}
											filteredPartners={filteredPartners}
											markersFromServer={markersFromServer}
										/>
									}
								/>
								<Route
									path="/kontakt"
									element={
										<KontaktPage
											partners={partners}
											markersFromServer={markersFromServer}
										/>
									}
								/>

								<Route path="/admin" element={<Admin />} />
								<Route
									path="/index.php/kalkulator"
									element={<Navigate to="/" />}
								/>
								<Route path="/embed" element={<PureRechner />} />
								<Route path="/embed/arbeitnehmer" element={<PureRechner />} />
								<Route
									path="/embed/portal"
									element={<PureRechner internal />}
								/>
								<Route
									path="/index.php/faq/"
									element={<Navigate to="/faq" />}
								/>
								<Route
									path="/index.php/fachhandler-auflistung/"
									element={<Navigate to="/haendlermap" />}
								/>
								<Route
									path="/index.php/handlermap/"
									element={<Navigate to="/haendlermap" />}
								/>
								<Route
									path="/index.php/partnerfirmen/"
									element={<Navigate to="/arbeitgeber" />}
								/>
								<Route
									path="/index.php/ablaufe/"
									element={<Navigate to="/ablauf" />}
								/>
								<Route
									path="/index.php/impressum/"
									element={<Navigate to="/impressum" />}
								/>
								<Route
									path="/index.php/fahrradmarken/"
									element={<Navigate to="/marken" />}
								/>
								<Route
									path="/index.php/cookie-policy/"
									element={<Navigate to="/cookie-policy" />}
								/>
								<Route
									path="/index.php/datenschutzerklarung/"
									element={<Navigate to="/datenschutzerklarung" />}
								/>
								<Route
									path="/index.php/hilfebereich-fur-handler/"
									element={<Navigate to="/hilfebereich-fur-handler" />}
								/>
								<Route path="/index.php/jobs/" element={<Navigate to="/" />} />
								<Route path="/index.php" element={<Navigate to="/" />} />
								<Route path="*" element={<Error404 />} />
							</Routes>
						</Suspense>
					</CSSTransition>
				</TransitionGroup>
			</LoadScriptOnlyIfNeeded>

			{fullPage && <Footer />}
		</div>
	);
};

export default App;
