import React from "react";
import { Helmet } from "react-helmet";
import MultiMap from "../components/MultiMap";
import Spacer from "../components/Spacer";

const Haendlermap = ({ partners, markersFromServer }) => (
	<div>
		<Helmet>
			<title>Radl-Händler und Bike-Marken | FIRMENRADL</title>
			<meta
				name="description"
				content="Jetzt Top-Bike leasen und bis zu 35 % günstiger finanzieren als bei Kauf! Inklusive Reparaturen und Versicherung. Details und Rechenbeispiel auf firmenradl.at"
			/>
		</Helmet>
		<Spacer />
		<MultiMap
			partners={partners}
			markersFromServer={markersFromServer}
			partnersOnly
		/>
	</div>
);

export default Haendlermap;
