import React from "react";
import "./Advantage.scss";
import PropTypes from "prop-types";
import Image from "./Image";

function Advantage({ img, headline, text }) {
	return (
		<div className="Advantage">
			<div className="Advantage-Text">
				<h2>{headline}</h2>
				{text}
			</div>
			<div className="Advantage-Image">
				<Image src={img} width={650} height={650} crop alt={"Vorteil 1"} />
			</div>
		</div>
	);
}

Advantage.propTypes = {
	img: PropTypes.string,
	headline: PropTypes.string,
	text: PropTypes.object,
};

export default Advantage;
