import React from "react";
import "./Step.scss";

function Step({ headline, number, text }) {
	return (
		<div className="Step">
			<div className="Step-Headline">
				<div className="Step-Bubble">
					<h3>{headline}</h3>
					<h4>{number}</h4>
				</div>
			</div>
			<p>{text}</p>
		</div>
	);
}

export default Step;
