import React, { useEffect, useMemo, useState } from "react";
import "./Rechner.scss";
import CurrencyInput from "react-currency-input-field";
import TextTransition, { presets } from "react-text-transition";

import icon_arrow_down from "../assets/ic_arrow_down.svg";
import icon_close from "../assets/icon_close.svg";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import Image from "./../components/Image";
import Tooltip from "./Tooltip";

let timer = null;

function Rechner({ className = "", internal = false }) {
	const [einkommen, setEinkommen] = useState(3590);
	const [fahrradPreis, setFahrradPreis] = useState(4999);
	const [laufzeit, setLaufzeit] = useState(24);
	const [versicherung, setVersicherung] = useState(1);
	const [vorsteuerabzug, setVorsteuerabzug] = useState(true);
	const [valueWVO, setValueWVO] = useState(false);
	const [pendlerpauschaleActive, setPendlerpauschaleActive] = useState(false);
	const [pendlerpauschale, setPendlerpauschale] = useState(50);
	const [familienbonusActive, setFamilienbonusActive] = useState(false);
	const [fbBis17, setFbBis17] = useState(0);
	const [fbAb18, setFbAb18] = useState(0);

	const [priceDirection, setPriceDirection] = useState("down");
	const [discountDirection, setDiscountDirection] = useState("down");

	const [advancedMode, setAdvancedMode] = useState(true);

	const [modalVersicherungOpen, setModalVersicherungOpen] = useState(false);
	const [calculator, setCalculator] = useState({
		MonatlicheZahlung: 46.93709870433122,
		ErsparnisInEuro: 998.5096310960507,
		ErsparnisInProzent: 39.94038524384203,
		Restwert: 375,
		RestwertInProzent: 0.15,
		MonatlicheZahlungAG: 97.15975156767911,
		RGG: 41.13900055706134,
		Versicherung: 9.6,
		RestwertInProzentText: "15",
		ErsparnisInEuroText: "998,51 €",
		ErsparnisInProzentText: "35",
		calculationParams: {
			Einkommen: 3590,
			FahrradPreis: 4999,
			Versicherung: 1,
			Laufzeit: 24,
			Vorsteuerabzug: true,
			SvAbzug: true,
			ValueWVO: false,
		},
		gesamtPreis: "3.240,53 €",
		MonatlicheZahlungText: "103,78 €",
		MonatlicheZahlungAGText: "97,16 €",
		RestwertText: "749,85 €",
		SteuerpflichtBetrag: "",
	});

	const calculationParams = useMemo(() => {
		const body = {
			Einkommen: einkommen,
			FahrradPreis: fahrradPreis,
			Versicherung: versicherung,
			Laufzeit: laufzeit,
			Vorsteuerabzug: vorsteuerabzug,
			SvAbzug: true,
			ValueWVO: valueWVO,
		};

		if (pendlerpauschaleActive && pendlerpauschale) {
			body.PendlerPauschale = {
				Id: 1,
				Km: pendlerpauschale,
			};
		}

		if (familienbonusActive && fbBis17 + fbAb18) {
			body.Familienbonus = {
				Id: 1,
				KinderBis17: fbBis17,
				KinderAb18: fbAb18,
			};
		}

		return JSON.stringify(body);
	}, [
		einkommen,
		fahrradPreis,
		laufzeit,
		versicherung,
		vorsteuerabzug,
		valueWVO,
		pendlerpauschaleActive,
		pendlerpauschale,
		familienbonusActive,
		fbBis17,
		fbAb18,
	]);

	// get window width and height for image resizing
	let width = window.innerWidth;
	let height = window.innerHeight;

	// get a larger photo for mobile
	if (width < 800) {
		width = width * 2;
	}

	// reducing width and height to a multiple of 64 to achieve less caching and resizing
	width = width - (width % 64);
	height = height - (height % 64);

	let modal = null;

	if (modalVersicherungOpen) {
		modal = (
			<div className={"Rechner-Modal"}>
				<div
					className="Rechner-Modal-Closer"
					onClick={() => setModalVersicherungOpen(false)}
					onKeyDown={(e) =>
						e.key === "escape" && setModalVersicherungOpen(false)
					}
				/>
				<div className="Rechner-Modal-Content">
					<button
						className="Rechner-Modal-Close"
						onClick={() => setModalVersicherungOpen(false)}
						onKeyDown={(e) =>
							e.key === "escape" && setModalVersicherungOpen(false)
						}
						type={"button"}
					>
						<img
							src={icon_close}
							width={24}
							height={24}
							alt={"Fenster Schliessen"}
						/>
					</button>
					<h2>WAS BEINHALTEN DIE BEIDEN VERSICHERUNGSPAKETE?</h2>
					<p>
						Es gibt zwei verschiedene Versicherungspakete aus denen du frei
						wählen kannst. Diese Pakete wurden exklusiv für FIRMENRADL generiert
						und finden sich kein weiteres Mal auf dem Markt. Die
						Versicherungspakete sind bereits in der Ersparnis inklusive.
					</p>
					<div className="row">
						<div className="col">
							<h3>Basis-Schutz</h3>
							<h4>
								Reparaturkosten-Übernahme bei Fahrrad-/E-Bike/Pedelec-Defekten
								durch:
							</h4>
							<ul>
								<li>Fall-/Sturzschäden</li>
								<li>Unfallschäden</li>
								<li>Unsachgemäße Handhabung</li>
								<li>Vandalismus</li>
								<li>Feuchtigkeit</li>
								<li>Elektronikschäden</li>
							</ul>
							<br />
							Inklusive: Arbeitslohn und Ersatzteile
							<h4>Bei Totalschaden</h4>
							<ul>
								<li>
									Vereinbarte Ablösesumme aus dem zugrundeliegenden
									Leasingvertrag, max. jedoch die Versicherungssumme.
								</li>
							</ul>
							<h4>Diebstahlschutz</h4>
							<ul>
								<li>
									Vereinbarte Ablösesumme aus dem zugrundeliegenden
									Leasingvertrag, max. jedoch die Versicherungssumme.
								</li>
								<li>
									Ersatz der Kosten für die neuen Teile und Einbaukosten bei
									Teile-Diebstahl.
								</li>
							</ul>
							<h4>Pick-up-Service</h4>
							Kostenübernahme bei Ausfall des Fahrrades/E-Bikes/Pedelecs während
							einer Ausfahrt durch:
							<ul>
								<li>
									Beschädigung oder Diebstahl des Fahrrades/E-Bikes/Pedelecs
								</li>
								<li>
									Ausfall des Motors/der Motorunterstützung auf Grund eines
									Defekts
								</li>
								<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
								<li>Reifenpanne</li>
								<li>Unfall/Sturz</li>
							</ul>
							<h4>Akkuschutz</h4>
							<ul>
								<li>Unsachgemäße Handhabung</li>
								<li>Feuchtigkeit</li>
								<li>Elektronikschäden</li>
							</ul>
							<br />
							Inklusive: Arbeitslohn und Ersatzteile
							<h4>Ausfallschutz für Arbeitgeber</h4>
							Kostenübernahme in Höhe der verbliebenen Leasingraten, max. jedoch
							die Versicherungssumme, bei Ausfall des Arbeitnehmers.
						</div>
						<div className="col">
							<h3>Premium-Schutz </h3>
							<h4>
								Reparaturkosten-Übernahme bei Fahrrad/E-Bike/Pedelec-Defekten
								durch:
							</h4>
							<ul>
								<li>Fall-/Sturzschäden</li>
								<li>Unfallschäden</li>
								<li>Unsachgemäße Handhabung</li>
								<li>Vandalismus</li>
								<li>Feuchtigkeit</li>
								<li>Elektronikschäden</li>
								<li>
									<em>Verschleiß</em>
								</li>
							</ul>
							Inklusive: Arbeitslohn und Ersatzteile
							<h4>Bei Totalschaden</h4>
							<ul>
								<li>
									Vereinbarte Ablösesumme aus dem zugrundeliegenden
									Leasingvertrag, max. jedoch die Versicherungssumme.
								</li>
							</ul>
							<h4>Diebstahlschutz</h4>
							<ul>
								<li>
									Vereinbarte Ablösesumme aus dem zugrundeliegenden
									Leasingvertrag, max. jedoch die Versicherungssumme.
								</li>
								<li>
									Ersatz der Kosten für die neuen Teile und Einbaukosten bei
									Teile-Diebstahl.
								</li>
							</ul>
							<h4>Pick-up-Service</h4>
							Kostenübernahme bei Ausfall des Fahrrades/E-Bikes/Pedelecs während
							einer Ausfahrt durch:
							<ul>
								<li>
									Beschädigung oder Diebstahl des Fahrrades/E-Bikes/Pedelecs
								</li>
								<li>
									Ausfall des Motors/der Motorunterstützung auf Grund eines
									Defekts
								</li>
								<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
								<li>Reifenpanne</li>
								<li>Unfall/Sturz</li>
							</ul>
							<h4>Akkuschutz</h4>
							<ul>
								<li>
									<em>Verschleiß, Abnutzung, Alterung</em>
								</li>
								<li>Unsachgemäße Handhabung</li>
								<li>Feuchtigkeit</li>
								<li>Elektronikschäden</li>
							</ul>
							Inklusive: Arbeitslohn und Ersatzteile
							<h4>Ausfallschutz für Arbeitgeber</h4>
							Kostenübernahme in Höhe der verbliebenen Leasingraten, max. jedoch
							die Versicherungssumme, bei Ausfall des Arbeitnehmers.
						</div>
					</div>
				</div>
			</div>
		);
	}

	let image = null;
	if (window.innerWidth < 800) {
		image = (
			<Image
				src={"assets/mood_red_hero_mobile.jpg"}
				width={368}
				height={800}
				crop={true}
				alt={"Hol dir dein Firmenradl!"}
				visibleByDefault
				fetchpriority={"high"}
			/>
		);
	} else {
		image = (
			<Image
				src={"assets/mood_red_hero2.jpg"}
				width={width}
				height={height}
				crop={false}
				alt={"Hol dir dein Firmenradl!"}
				visibleByDefault
				fetchpriority={"high"}
			/>
		);
	}

	const parseNumber = (number) => {
		let numberClean = number;
		numberClean = numberClean.replaceAll(" ", "");
		numberClean = numberClean.replaceAll(".", "");

		return numberClean;
	};

	useEffect(
		() => {
			if (
				!calculator.calculationParams ||
				calculator.calculationParams !== calculationParams
			) {
				const url =
					process.env.NODE_ENV === "development"
						? "https://test-firmenradl.intersport.at/rest/calculate/leasing"
						: "/rest/calculate/leasing";

				if (timer) {
					clearTimeout(timer);
				}

				timer = setTimeout(() => {
					fetch(url, {
						method: "POST",
						headers: {
							Accept: "text/plain",
							"Content-Type": "application/json",
						},
						body: calculationParams,
					})
						.then((response) => response.json())
						.then((response) => {
							if (JSON.stringify(response) !== JSON.stringify(calculator)) {
								if (!response.status) {
									if (calculator.ErsparnisInEuro > response.ErsparnisInEuro) {
										setDiscountDirection("up");
										setPriceDirection("down");
									} else {
										setDiscountDirection("down");
										setPriceDirection("up");
									}

									const newCalculator = { ...response };

									newCalculator.RestwertInProzentText = (
										response.RestwertInProzent * 100
									).toLocaleString("de-DE", {
										maximumFractionDigits: 0,
									});

									newCalculator.ErsparnisInEuroText =
										response.ErsparnisInEuro.toLocaleString("de-DE", {
											style: "currency",
											currency: "EUR",
										});

									newCalculator.ErsparnisInProzentText =
										response.ErsparnisInProzent.toLocaleString("de-DE", {
											maximumFractionDigits: 0,
										});

									newCalculator.calculationParams = calculationParams;
									const paramsAsObject = JSON.parse(calculationParams);

									newCalculator.gesamtPreis = (
										response.Restwert +
										response.MonatlicheZahlung * paramsAsObject.Laufzeit
									).toLocaleString("de-DE", {
										style: "currency",
										currency: "EUR",
									});

									newCalculator.MonatlicheZahlungText =
										response.MonatlicheZahlung.toLocaleString("de-DE", {
											style: "currency",
											currency: "EUR",
										});

									newCalculator.MonatlicheZahlungAGText =
										response.MonatlicheZahlungAG.toLocaleString("de-DE", {
											style: "currency",
											currency: "EUR",
										});

									newCalculator.RestwertText = response.Restwert.toLocaleString(
										"de-DE",
										{
											style: "currency",
											currency: "EUR",
										},
									);

									setCalculator(newCalculator);
								}

								clearTimeout(timer);
								timer = 0;
							}
						})
						.catch((error) => {
							console.error("Error:", error);
							timer = 0;
						});
				}, 300);
			} else {
				// same data, skip calc
			}
		},
		// ran into a problem with the timeout using usestate, but it works like that, so I will leave that to bet
		// refactored later on.
		// it has to do with the timeout of the response, i need their values, but I don't need them up to date

		[calculator, calculationParams],
	);

	return (
		<div className={`Rechner ${className}`}>
			{modal}
			<div className="Rechner-Content">
				<div className="Rechner-HeroImage">{image}</div>
				{!internal && (
					<div className="Rechner-Awards">
						<Image
							src={"assets/Awards_EC_Firmenradl_AT.png"}
							width={128}
							height={128}
							crop={false}
							alt={"Hol dir dein Firmenradl!"}
							visibleByDefault
							fetchpriority={"high"}
						/>
					</div>
				)}

				<div className="Rechner-Slider">
					<div className="Bubbles desktop-only">
						<div className={"Bubble Big"}>
							<small>bis zu</small>
							<TextTransition
								springConfig={presets.wobbly}
								className="textTransition"
								direction={discountDirection}
							>
								{calculator.ErsparnisInEuroText}
							</TextTransition>
							<small>sparen</small>
						</div>
						<div className="Bubble Small">
							<small>bis zu</small>
							<TextTransition
								springConfig={presets.wobbly}
								className="textTransition"
								direction={discountDirection}
							>
								{calculator.ErsparnisInProzentText}%
							</TextTransition>
							<small>günstiger</small>
						</div>
					</div>
				</div>
				<div className="Rechner-Form">
					<h1>
						Ersparnis<span className={"outline"}>Rechner</span>
					</h1>

					<div className="row">
						<div className="col">
							<p className="subline">
								Mit FIRMENRADL kannst du dir ein Fahrrad deiner Wahl stark
								vergünstigt holen, rechne dir gleich jetzt die Ersparnis aus und
								radel los!
							</p>
						</div>
					</div>

					<div className="row main-row">
						<div className="col Tooltip">
							<label htmlFor="fahrradPreis">Fahrrad-Preis</label>
							<CurrencyInput
								id="fahrradPreis"
								name="fahrradPreis"
								placeholder="Preis in EUR"
								defaultValue={fahrradPreis}
								decimalsLimit={2}
								intlConfig={{ locale: "de-DE", currency: "EUR" }}
								className="currency"
								onChange={(e) => {
									setFahrradPreis(
										Number.parseFloat(parseNumber(e.target.value)),
									);
									parseNumber(e.target.value);
								}}
							/>
							<Tooltip>
								Gib hier bitte den Fahrrad-Preis inkl. Zubehör und Steuer ein
							</Tooltip>
						</div>

						<div className="col Tooltip">
							<label htmlFor="einkommen">Bruttogehalt / Monat</label>
							<CurrencyInput
								id="einkommen"
								name="einkommen"
								placeholder="Einkommen in EUR"
								defaultValue={einkommen}
								decimalsLimit={2}
								intlConfig={{ locale: "de-DE", currency: "EUR" }}
								className="currency"
								onChange={(e) =>
									setEinkommen(Number.parseFloat(parseNumber(e.target.value)))
								}
							/>
							<Tooltip>
								Gib hier bitte dein tatsächliches Bruttogehalt pro Monat ein
							</Tooltip>
						</div>

						<div className="col radioGroupContainer Tooltip">
							<label>Laufzeit in Monaten</label>
							<div className="radioGroup">
								<label className={laufzeit === 24 ? "checked" : "not-checked"}>
									<input
										type="radio"
										name={"laufzeit"}
										checked={laufzeit === 24}
										onChange={() => setLaufzeit(24)}
									/>{" "}
									24
								</label>
								<label className={laufzeit === 36 ? "checked" : "not-checked"}>
									<input
										type="radio"
										name={"laufzeit"}
										checked={laufzeit === 36}
										onChange={() => setLaufzeit(36)}
									/>{" "}
									36
								</label>
								<label className={laufzeit === 48 ? "checked" : "not-checked"}>
									<input
										type="radio"
										name={"laufzeit"}
										checked={laufzeit === 48}
										onChange={() => setLaufzeit(48)}
									/>{" "}
									48
								</label>
							</div>
							<Tooltip>
								Wähle hier bitte deine gewünschte Laufzeit.
								<br />
								Vergewissere dich aber auch ob in
								<br />
								deinem Unternehmen die Laufzeit möglich ist.
							</Tooltip>
						</div>
					</div>
					{fahrradPreis < 750 || fahrradPreis > 10000 ? (
						<div className="row">
							<div className="col">
								<span className={"text-center"}>
									Der Fahrradpreis muss zwischen 750 € und 10.000 € liegen
								</span>
							</div>
						</div>
					) : null}

					{!advancedMode && (
						<div
							className="extensionToggle"
							onClick={() => setAdvancedMode(!advancedMode)}
							onKeyDown={(e) =>
								e.key === "escape" && setAdvancedMode(!advancedMode)
							}
						>
							<img
								src={icon_arrow_down}
								alt="Weitere Optionen anzeigen"
								className={advancedMode ? "rotated" : ""}
							/>
							Erweiterte Berechnung anzeigen
							<img
								src={icon_arrow_down}
								alt="Weitere Optionen anzeigen"
								className={advancedMode ? "rotated" : ""}
							/>
						</div>
					)}

					{advancedMode && (
						<div className={"extendedOptions"}>
							<div className="radioGroupContainer Tooltip">
								<Tooltip>
									Wähle hier dein gewünschtes Versicherungspaket.
									<br />
									Die Inhalte findest du unter weitere Infos.
									<br />
									Vergewissere dich auch hier wie die Vorgaben in deinem
									Unternehmen sind.
								</Tooltip>
								<div className="labelContainer">
									<label>Versicherungspaket</label>
									<button
										onClick={() => setModalVersicherungOpen(true)}
										type={"button"}
									>
										Weitere Infos
									</button>
								</div>
								<div className="radioGroup">
									<label
										className={versicherung === 1 ? "checked" : "not-checked"}
									>
										<input
											type="radio"
											name={"versicherung"}
											value="1"
											checked={versicherung === 1}
											onChange={(e) => setVersicherung(Number(e.target.value))}
										/>{" "}
										Basis
									</label>
									<label
										className={versicherung === 2 ? "checked" : "not-checked"}
									>
										<input
											type="radio"
											name={"versicherung"}
											value="2"
											checked={versicherung === 2}
											onChange={(e) => setVersicherung(Number(e.target.value))}
										/>{" "}
										Premium (inkl. Verschleiß)
									</label>
									{/* there was the idea of another insurance option, this has been cancelled so far */}

									{/*<label className={versicherung === 3 ? "checked" : "not-checked"}>*/}
									{/*  <input*/}
									{/*    type="radio"*/}
									{/*    name={"versicherung"}*/}
									{/*    value="3"*/}
									{/*    checked={versicherung === 3}*/}
									{/*    onChange={(e) => setVersicherung(Number(e.target.value))}*/}
									{/*  />{" "}*/}
									{/*  Premium++*/}
									{/*</label>*/}
								</div>
							</div>

							<div className="radioGroupContainer Tooltip">
								<div className="labelContainer">
									<label>Restwert</label>
								</div>
								<div className="radioGroup">
									<label
										className={valueWVO === false ? "checked" : "not-checked"}
									>
										<input
											type="radio"
											name={"valueWVO"}
											value="1"
											checked={valueWVO === false}
											onChange={() => setValueWVO(false)}
										/>{" "}
										Firmenradl-Restwert
									</label>
									<label
										className={valueWVO === true ? "checked" : "not-checked"}
									>
										<input
											type="radio"
											name={"valueWVO"}
											value="2"
											checked={valueWVO === true}
											onChange={() => setValueWVO(true)}
										/>{" "}
										Restwert laut Werteverordnung RZ 207
									</label>
								</div>
								{!valueWVO && (
									<div className="infoText">
										<p>
											Wenn der Firmenradl-Restwert geringer ist als der um
											übliche Preisnachlässe verminderte Endpreis des
											Abgabeortes, kommt es zu einer Steuerpflicht iHv EUR{" "}
											{Number.parseFloat(calculator.SteuerpflichtBetrag)
												.toFixed(2)
												.replace(".", ",")}
											.
										</p>
									</div>
								)}
							</div>

							<div className="checkboxGroupSet">
								<div className="checkboxGroupContainer Tooltip">
									<label htmlFor="vorsteuerabzug">
										<input
											name="vorsteuerabzug"
											type="checkbox"
											checked={vorsteuerabzug}
											onChange={() => {
												setVorsteuerabzug(!vorsteuerabzug);
											}}
										/>
										Mein Arbeitgeber ist vorsteuerabzugsberechtigt
										{/*Ist das Unternehmen vorsteuerabzugsberechtigt?*/}
									</label>
									<Tooltip>
										Nutze diese Checkbox, wenn dein Arbeitgeber
										vorsteuerabzugsberechtigt ist,
										<br />
										und ihr im Unternehmen den Abzug auch geltend macht.
									</Tooltip>
								</div>

								<div className="checkboxGroupContainer">
									<label htmlFor="pendlerpauschaleActive">
										<input
											name="pendlerpauschaleActive"
											type="checkbox"
											checked={pendlerpauschaleActive}
											onChange={() => {
												setPendlerpauschaleActive(!pendlerpauschaleActive);
											}}
										/>
										Ich beziehe die Pendlerpauschale{" "}
										<span className={pendlerpauschaleActive ? "" : "hidden"}>
											für
											<input
												id="pendlerpauschale"
												name="pendlerpauschale"
												placeholder="50"
												defaultValue={pendlerpauschale}
												onChange={(e) =>
													setPendlerpauschale(parseNumber(e.target.value))
												}
											/>{" "}
											km pro Strecke.
										</span>
									</label>
								</div>

								<div className="checkboxGroupContainer">
									<label htmlFor="familienbonusActive">
										<input
											name="familienbonusActive"
											type="checkbox"
											checked={familienbonusActive}
											onChange={() => {
												setFamilienbonusActive(!familienbonusActive);
											}}
										/>
										Ich beziehe einen Familienbonus
									</label>
								</div>

								{familienbonusActive && (
									<div>
										<div className="row indent">
											<div className="col">
												<label htmlFor="fbBis17">Kinder bis 17 Jahre</label>
												<input
													id="fbBis17"
													name="fbBis17"
													placeholder="0"
													defaultValue={fbBis17}
													onChange={(e) =>
														setFbBis17(
															Number.parseInt(parseNumber(e.target.value)),
														)
													}
												/>
											</div>
											<div className="col">
												<label htmlFor="fbAb18">Kinder ab 18 Jahre</label>
												<input
													id="fbAb18"
													name="fbAb18"
													placeholder="0"
													defaultValue={fbAb18}
													onChange={(e) =>
														setFbAb18(
															Number.parseInt(parseNumber(e.target.value)),
														)
													}
												/>
											</div>
										</div>
										{fbAb18 + fbBis17 < 1 && (
											<div className="row indent">
												<div
													className="col text-center"
													style={{ marginTop: 8 }}
												>
													<small>
														Mindestens 1 Kind muss angegeben werden.
													</small>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					)}

					{!internal && (
						<div className="ButtonContainer">
							<div className="BubblesContainer mobile-only">
								<div className="Bubbles mobile-only">
									<div className="Bubble Big">
										<TextTransition
											springConfig={presets.wobbly}
											className="textTransition"
											direction={discountDirection}
										>
											{calculator.ErsparnisInEuroText}
										</TextTransition>
										<small>sparen</small>
									</div>
								</div>
							</div>

							<a href={getRegisterUrl()} className="button">
								Jetzt bis zu{" "}
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									<span key={"asdfasdf"}>
										{" "}
										{calculator.ErsparnisInProzentText}
									</span>
								</TextTransition>
								% Preisvorteil sichern
							</a>
						</div>
					)}

					<div className={`row summary ${internal ? "internal" : ""}`}>
						{internal && (
							<div className="col">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									{calculator.ErsparnisInProzentText} %
								</TextTransition>
								<small>Preisvorteil für Mitarbeiter</small>
							</div>
						)}
						<div className="col">
							<TextTransition
								springConfig={presets.wobbly}
								className="textTransition"
								direction={discountDirection}
							>
								{calculator.RestwertText}
							</TextTransition>
							<small>
								Restwert (
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
									inline
								>
									{calculator.RestwertInProzentText} %
								</TextTransition>
								)
							</small>
						</div>
						{internal && (
							<div className="col">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									{calculator.ErsparnisInEuroText}
								</TextTransition>
								<small>Ersparnis</small>
							</div>
						)}

						{internal && (
							<div className="col">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={priceDirection}
								>
									{calculator.MonatlicheZahlungAGText}
								</TextTransition>
								<small>Monatliche Rate vor Gehaltsumwandlung</small>
							</div>
						)}
						<div className="col Tooltip">
							<TextTransition
								springConfig={presets.wobbly}
								className="textTransition"
								direction={priceDirection}
							>
								{calculator.MonatlicheZahlungText}
							</TextTransition>
							<small>Monatliche Rate nach Gehaltsumwandlung</small>
							<Tooltip>Monatliche Belastung von deinem Nettogehalt</Tooltip>
						</div>

						<div className="col Tooltip">
							<TextTransition
								springConfig={presets.wobbly}
								className="textTransition"
								direction={priceDirection}
							>
								{calculator.gesamtPreis}
							</TextTransition>

							<small>Gesamtpreis</small>
							<Tooltip>
								Gesamtsumme aus den <br />
								{laufzeit} monatlichen Raten je{" "}
								{calculator.MonatlicheZahlungText} + Restwert (
								{calculator.RestwertText})
							</Tooltip>
						</div>
					</div>

					<small>
						Dieser Rechner bietet eine Richtlinie wie die Ersparnis mit
						FIRMENRADL aussehen könnte. Der tatsächliche Betrag hängt von den
						tatsächlichen Bezügen und Abzügen des Arbeitnehmers ab. Um eine
						100&nbsp;% korrekte Auskunft zu erhalten kontaktiere bitte deinen
						Arbeitgeber bzw. die Personalabrechnungsabteilung deines
						Unternehmens.
					</small>
				</div>
			</div>
		</div>
	);
}

export default Rechner;
