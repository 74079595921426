import React from "react";
import "./Brand.scss";
import PropTypes from "prop-types";
import { getBaseUrl } from "../helpers/getBaseUrl";
import Image from "./Image";

function Brand({ name, logo, id, open, imageVisibleByDefault = false }) {
	let img = <div />;

	if (logo.indexOf(".svg") > 0) {
		// image is an svg
		const baseUrl = getBaseUrl(true, "/logo/");

		img = <img src={`${baseUrl}${logo}`} alt={name} width={150} height={150} />;
	} else if (logo.length > 5) {
		img = (
			<Image
				src={logo}
				width={150}
				height={150}
				alt={name}
				crop={false}
				fetchpriority={"low"}
				logoUrl
				visibleByDefault={imageVisibleByDefault}
			/>
		);
	}

	return (
		<button
			className="Brand"
			href={`#${id}`}
			onClick={() => open(id)}
			type={"button"}
		>
			<div className="Brand-image">{img}</div>
			<div className="Brand-text">
				<h3>{name}</h3>
			</div>
		</button>
	);
}

Brand.propTypes = {
	name: PropTypes.string,
	logo: PropTypes.string,
	id: PropTypes.string,
	open: PropTypes.func,
	imageVisibleByDefault: PropTypes.bool,
};

export default Brand;
