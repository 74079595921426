import React from "react";
import "./Advantages.scss";
import PropTypes from "prop-types";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import Advantage from "./Advantage";
import AnimatedKringel from "./AnimatedKringel";

function Advantages({
	advantages,
	location = "user",
	buttonText = "JETZT REGISTRIEREN & LOSFAHREN",
}) {
	if (advantages === false) {
		return <div>Loading...</div>;
	}

	return (
		<div className="Advantages AnimatedKringelParent">
			<AnimatedKringel
				text={"Alles meine Vorteile? Abgefahren!"}
				wordOffsetDesktop={3}
				wordOffsetMobile={3}
				everyNWordsMobile={4}
				spacerHeight={500}
				marginBottomMobile={"-100vw"}
				pathVar={2}
				scrollSpeedDesktop={1.7}
			/>
			<div className="Advantages-List">
				{advantages.map((advantage) => (
					<Advantage
						key={`advantages_${location}_${advantage.content}`}
						headline={advantage.title}
						img={advantage.image}
						text={
							<div className={"content"}>
								<p dangerouslySetInnerHTML={{ __html: advantage.content }} />
							</div>
						}
					/>
				))}
				<div className="Advantages-Banner">
					<a
						href={getRegisterUrl()}
						className="button"
						target="_blank"
						rel="noreferrer"
					>
						{buttonText}
					</a>
				</div>
			</div>
		</div>
	);
}
Advantages.propTypes = {
	advantages: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	location: PropTypes.string,
	buttonText: PropTypes.string,
};

export default Advantages;
