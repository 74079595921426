import PropTypes from "prop-types";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getBaseUrl } from "../helpers/getBaseUrl";

function limitNumberWithinRange(num, min, max) {
	const MIN = min || 1;
	const MAX = max || 20;
	const parsed = Number.parseInt(num);
	return Math.min(Math.max(parsed, MIN), MAX);
}

const Image = ({
	src,
	height = 0,
	width = 580,
	className = "",
	alt = "",
	title = false,
	crop = false,
	visibleByDefault = false,
	fetchpriority = "auto",
	logoUrl = false,
}) => {
	const baseUrl = getBaseUrl(
		true,
		logoUrl ? "/logo/" : "/img/",
		false,
		false,
		true,
	);

	// reducing width and height to a multiple of 8 to achieve less caching and resizing
	width = width - (width % 8);
	width = Math.round(limitNumberWithinRange(width, 16, 2048));

	if (height > 0) {
		height = height - (height % 8);
		height = Math.round(limitNumberWithinRange(height, 16, 2048));
	}

	const cropParam = !crop ? "" : "&fit=crop"; // crop by default

	const w = `&w=${width}`;
	const h = height > 0 ? `&h=${height}${cropParam}` : "";
	const wSmall = `&w=${width}`;

	if (src.indexOf("placeholder") > 0) {
		// image is a placeholder, let's avoid bad http calls
		return null;
	}

	const params = `${w}${h}`;
	const basicSrc = `${baseUrl}${src}?fm=pjpg${wSmall}`;

	return (
		<picture className={className}>
			<source
				srcSet={`${baseUrl}${src}?fm=webp${params}&q=90 1x, ${baseUrl}${src}?fm=webp${params}&dpr=1.5&q=80 1.5x, ${baseUrl}${src}?fm=webp${params}&dpr=2&q=75 2x`}
				type="image/webp"
			/>
			<source
				srcSet={`${baseUrl}${src}?fm=pjpg${params}&q=90 1x, ${baseUrl}${src}?fm=pjpg${params}&dpr=1.5&q=80 1.5x, ${baseUrl}${src}?fm=pjpg${params}&dpr=2&q=75 2x`}
				type="image/jpeg"
			/>

			<LazyLoadImage
				src={basicSrc}
				alt={alt}
				title={title ? title : alt}
				visibleByDefault={visibleByDefault}
				fetchpriority={fetchpriority}
				threshold={300}
			/>
		</picture>
	);
};

Image.propTypes = {
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	src: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	crop: PropTypes.bool,
};

export default Image;
