import React from "react";
import "./MapMarkerPopup.scss";
import Image from "./Image";

import social_fb from "../assets/social_fb.svg";
import social_ig from "../assets/social_ig.svg";
import social_li from "../assets/social_li.svg";

const Content = ({ data }) => {
	let brands = null;

	if (typeof data.bikes === "object" && data.bikes.length) {
		brands = (
			<div>
				<b>Marken</b>
				<ul className={"brands"}>
					{data.bikes.map((bike) => (
						<li key={`bike_brand_${bike.id}`}>{bike.name}</li>
					))}
				</ul>
			</div>
		);
	}

	const socialMedia = [];

	let homepageUrl = false;
	if (data.homepage) {
		if (data.homepage.indexOf("http") < 0) {
			homepageUrl = `http://${data.homepage}`;
		} else {
			homepageUrl = data.homepage;
		}
		socialMedia.push(
			<a
				target="_blank"
				rel="noreferrer"
				href={homepageUrl}
				className={"website"}
				key={`social_web_${data.id}`}
			>
				Webseite
			</a>,
		);
	}

	if (data.instagram) {
		socialMedia.push(
			<a
				key={`social_instagram_${data.id}`}
				target="_blank"
				rel="noreferrer"
				href={data.instagram}
			>
				<img src={social_ig} width={24} height={24} alt="instagram" />
			</a>,
		);
	}

	if (data.facebook) {
		socialMedia.push(
			<a
				key={`social_facebook_${data.id}`}
				target="_blank"
				rel="noreferrer"
				href={data.facebook}
			>
				<img src={social_fb} width={24} height={24} alt="facebook" />
			</a>,
		);
	}

	if (data.linkedin) {
		socialMedia.push(
			<a
				key={`social_linkedin_${data.id}`}
				target="_blank"
				rel="noreferrer"
				href={data.linkedin}
			>
				<img src={social_li} width={24} height={24} alt="linked in" />
			</a>,
		);
	}

	// console.log(data);

	return (
		<div className={"partner"}>
			<div className="header">
				<div className="text">
					<b>{data.title}</b>
					<br />
					<div className="address">
						{data.street} <br />
						{data.zip} {data.city}
					</div>
				</div>
				<div className="image">
					<Image src={data.logo} width={200} crop={false} logoUrl />
				</div>
			</div>
			<div className="footer">
				{brands}
				{socialMedia.length ? (
					<div className={"socials"}>{socialMedia}</div>
				) : null}
			</div>
		</div>
	);
};

function MapMarkerPopup({ marker }) {
	const renderedPartners = [];
	marker.data.partners.forEach((partner) =>
		renderedPartners.push(
			<Content data={partner} key={`partner_${partner.id}`} />,
		),
	);

	const renderedFirms = [];
	marker.data.firms.forEach((firm) =>
		renderedFirms.push(<Content data={firm} key={`firm_${firm.id}`} />),
	);

	console.log("data in markerPopup", marker);

	return (
		<div className={"MapMarkerPopup"}>
			{renderedPartners.length ? (
				<div className={"group"}>
					<h3>Radl-Händler</h3>
					{renderedPartners}
				</div>
			) : null}

			{renderedFirms.length ? (
				<div className={"group"}>
					<h3 className={"blue"}>Arbeitgeber</h3>
					{renderedFirms}
				</div>
			) : null}
		</div>
	);
}

export default MapMarkerPopup;
