import { filter } from "lodash";
import React, { useState } from "react";
import "./Brands.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import icon_close from "../assets/icon_close.svg";
import AnimatedKringel from "./AnimatedKringel";
import Brand from "./Brand";
import MultiMap from "./MultiMap";

function Brands({
	brands,
	randomBrands = [],
	showAll = false,
	partners = [],
	markersFromServer,
	filterPartnersByBrand = (partnersToFilter) => {
		console.log(partnersToFilter);
	},
}) {
	const [searchText, setSearchText] = useState("");
	const [openBrand, setOpenBrand] = useState(false);

	const marken = [];
	const markenElements = [];

	let modal = null;
	if (openBrand) {
		const brand = brands[openBrand];
		modal = (
			<div className={"Brands-Modal"}>
				<div
					className="Brands-Modal-Closer"
					onClick={() => setOpenBrand(false)}
					onKeyDown={(e) => e.key === "escape" && setOpenBrand(false)}
				/>
				<div className="Brands-Modal-Content">
					<button
						className="Brands-Modal-Close"
						onClick={() => setOpenBrand(false)}
						type={"button"}
					>
						<img
							src={icon_close}
							width={24}
							height={24}
							alt={"Fenster Schliessen"}
						/>
					</button>
					<MultiMap
						partnersOnly
						hideBanner
						partners={partners}
						markersFromServer={markersFromServer}
						headerText={`Radl-Händler mit ${brand.name}`}
					/>
				</div>
			</div>
		);
	}

	if (brands) {
		if (!showAll) {
			randomBrands.forEach((brand) => marken.push(brand));
		} else {
			if (searchText.length === 0) {
				// all brands
				Object.keys(brands).forEach((key) => {
					marken.push(brands[key]);
				});
			} else {
				// filter brands
				const filteredResults = filter(brands, (o) => {
					if (o.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
						return true;
					}
					return false;
				});

				filteredResults.forEach((brand) => marken.push(brand));
			}
		}

		marken.forEach((marke) => {
			markenElements.push(
				<Brand
					key={`brand_${marke.id}`}
					name={marke.name}
					id={marke.id}
					logo={marke.logo}
					open={(id) => {
						setOpenBrand(id);
						filterPartnersByBrand(id);
					}}
					imageVisibleByDefault={!showAll}
				/>,
			);
		});
	} else {
		// no brands yet received
		for (let j = 0; j < 12; j++) {
			markenElements.push(
				<Brand key={`brand_loading_${j}`} name={"..."} id={""} logo={""} />,
			);
		}
	}

	if (!showAll) {
		// small version on front page
		return (
			<div className="Marken AnimatedKringelParent" id={"brands"}>
				{modal}
				<AnimatedKringel
					text={"Top-Marken"}
					marginBottomMobile={"-200px"}
					everyNWordsMobile={5}
					wordOffsetMobile={3}
					pathVar={0}
				/>
				<div className="Marken-Content">
					<div className="Marken-group">{markenElements}</div>
					<div className="Marken-details row">
						<div className="col">
							{markenElements.length} / {Object.keys(brands).length} Marken
						</div>
						<div className="col col-button">
							<Link to="/marken" className="button">
								Alle Marken ansehen
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="Marken AnimatedKringelParent" id={"brands"}>
			{modal}
			<AnimatedKringel text={"Top-Marken"} marginBottom={"-25%"} />
			<div className="Marken-Content">
				<div className="row">
					<div className="col text-center">
						<input
							className="searchbox"
							type="text"
							name={"searchText"}
							placeholder={"Nach Marke suchen..."}
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
						/>
					</div>
				</div>

				<div className="Marken-group">{markenElements}</div>
			</div>
		</div>
	);
}

Brands.propTypes = {
	brands: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	randomBrands: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	showAll: PropTypes.bool,
	partners: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	markersFromServer: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	filterPartnersByBrand: PropTypes.func,
};

export default Brands;
